import { EncodeObject } from "@cosmjs/proto-signing";
import { useInput } from "../hooks/useInput";
import { SigningStargateClient } from "@cosmjs/stargate";
import { GasPrice } from "@cosmjs/stargate/build/fee";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  IconButton,
  styled
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  SixDataChainConnector,
  typesTxTokenManager
} from "@sixnetwork/six-data-chain-sdk";
import { ethers } from "ethers";
import { useMemo, useState } from "react";
import Web3 from "web3";
import abi from "../abi/UnwrapSIXToken_metadata.json";
import chainConfig from "../chainConfig";
import WhiteGradientButton from "../components/WhiteGradientButton";
import union from "../images/union.png";
import BoxStyle from "./BoxStyle";
import Coin from "./Coin";
import FailedBox from "./FailedBox";
import SuccessBox from "./SuccessBox";

const { chainId, rpc } = chainConfig;
const contractABI = abi.output.abi;
const web3 = new Web3(window.ethereum);
const contract = new web3.eth.Contract(
  contractABI,
  process.env.REACT_APP_CONTRACT_ADDRESS
);

const sendToCosmos = async (dst, amount, from) => {
  try {
    const amountWei = ethers.utils.parseEther(amount.toString());
    const tx = await contract.methods
      .transferToCosmos(dst, amountWei)
      .send({ from });
    return tx;
  } catch (error) {
    throw error;
  }
};

const convertToBillionStr = numStr => {
  // Convert the string to a number and multiply by 1 billion
  let num = parseFloat(numStr);
  let result = num * Math.pow(10, 6);

  return String(result).replace(/\.(\d)+/, "");
};

const signAndBroadcastTransaction = async (dst, amount, from) => {
  if (!window.keplr) {
    alert("Please install Keplr extension to proceed.");
    return;
  }

  const sixConnector = new SixDataChainConnector();
  sixConnector.rpcUrl = rpc;
  // Define your chain ID and endpoint
  const endpoint = rpc;

  // Request Keplr to enable access to the user's wallet
  await window.keplr.enable(chainId);

  // Get the offline signer from Keplr
  const offlineSigner = window.getOfflineSigner(chainId);

  // Create a SigningStargateClient instance using the offline signer
  const stargateClient = await SigningStargateClient.connectWithSigner(
    endpoint,
    offlineSigner
  );

  // Get the sender's address
  const [senderAddress] = await offlineSigner.getAccounts();

  const rpcClient = await sixConnector.connectRPCClient(offlineSigner, {
    gasPrice: GasPrice.fromString("1.25usix")
  });

  const msgArray = [];
  const convertMsg = {
    creator: from,
    amount: {
      denom: "usix",
      amount: convertToBillionStr(amount) // 100000000 == 100000000000000000000asix (100 six)
    },
    receiver: dst // wrao to evm address
    // receiver:address, // wrap to self
  };

  const msg = await rpcClient.tokenmngrModule.msgWrapToken(convertMsg);
  msgArray.push(msg);
  const txResponse = await rpcClient.tokenmngrModule.signAndBroadcast(
    msgArray,
    {
      fee: "auto",
      memo: `wrap usix to ${dst}`
    }
  );

  return txResponse;
};

const ToeknStyle = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #c7cbd5;
  border-radius: 12px;
  padding: 16px 24px;
`;

const formatAddress = address => {
  if (!address) return "";
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

const Token = ({ token, address }) => {
  return (
    <ToeknStyle>
      <Coin token={token} size={80} />
      <Typography mt={2} variant="caption">
        {formatAddress(address)}
      </Typography>
    </ToeknStyle>
  );
};

const ConfirmBox = ({ from, to, evmaccount, protocolaccount, onBack }) => {
  const { input } = useInput();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fromMetaMask = useMemo(() => {
    return from.name === "SIX Protocol - EVM";
  }, [from]);

  const fromAddress = useMemo(() => {
    return fromMetaMask ? evmaccount : protocolaccount;
  }, [fromMetaMask]);

  const toAddress = useMemo(() => {
    return fromMetaMask ? protocolaccount : evmaccount;
  }, [fromMetaMask]);

  const handleSubmit = async () => {
    try {
      if (fromMetaMask) {
        setIsLoading(true);
        const tx = await sendToCosmos(protocolaccount, input, evmaccount);
        setIsSuccess(true);
      } else {
        setIsLoading(true);
        const tx = await signAndBroadcastTransaction(
          evmaccount,
          input,
          protocolaccount
        );
        setIsSuccess(true);
      }
    } catch (error) {
      setIsFailed(true);
      setIsLoading(false);
      throw error;
    }
  };

  return (
    <>
      <BoxStyle>
        <IconButton
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            paddingTop: 3,
            paddingLeft: 4,
            paddingBottom: 3,
            paddingRight: 3,
            color: "white"
          }}
          onClick={onBack}
        >
          <ArrowBackIcon sx={{ fontSize: 28 }} />
        </IconButton>
        <Typography
          variant="h5"
          align="center"
          textTransform="capitalize"
          mb={1}
        >
          Token transfer
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns="1fr auto 1fr"
          columnGap={{ xs: 2, sm: 4 }}
          alignItems="center"
          my={3}
        >
          <Token token={from} address={fromAddress} />
          <img src={union} alt="" height={16} />
          <Token token={to} address={toAddress} />
        </Box>
        <Typography variant="h5" fontWeight="normal" mb={3} lineHeight={1}>
          {input} SIX
        </Typography>

        <Box display="flex" alignItems="center">
          <WhiteGradientButton disabled={isLoading} onClick={handleSubmit}>
            {isLoading ? (
              <>
                <CircularProgress size={16} sx={{ mr: 1 }} />
                Sending
              </>
            ) : (
              "Send"
            )}
          </WhiteGradientButton>
        </Box>
      </BoxStyle>

      <SuccessBox
        open={isSuccess}
        message="Token Transfer Successful"
        onClose={() => {
          setIsSuccess(false);
          onBack(true);
        }}
      />
      <FailedBox
        open={isFailed}
        message="Something went wrong."
        onClose={() => {
          setIsFailed(false);
          onBack(true);
        }}
      />
    </>
  );
};

export default ConfirmBox;
